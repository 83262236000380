<template>
  <div class="guide-cnt10">
    <h6 class="h6">注意事项</h6>
    <div class="cnt">
      <p class="p">1、本公司采用Photoshop CS6、CorelDRAW X8、Illustrator CS6 软件，因版本不同造成文件内容改变本公司无法给予补印或退货，敬请谅解；</p>
      <p class="p">2、请检查所有文字是否转曲线，文字未转曲线可能没有警告对话框提醒而造成漏字、跳字或用其他字体代替的现象，本公司无法给予补印或退货，敬请谅解；</p>
      <p class="p">3、成品切割误差2mm，请检查内容到边是否有足够的距离，在合理的范围内的误差本公司不给予退货或补印，敬请谅解。建议内容到边的距离最少为4mm；</p>
      <p class="p">4、请您完稿时，不要使用一些如套印填色、没封闭曲线的填色、填色的线条、ELE物件等，不能正常状况下检视出来的设定造成印刷遗漏错误本公司无法给予补印或退货，敬请谅解；</p>
      <p class="p">5、当您选择自助设计时，请仔细检查文件中所有的内容（包括但不限于logo的大小及像素过低导致的模糊文字的字体、字号、粗细，二维码的大小及像素过低导致的模糊图案、图片是否因像素过低导致的模糊，颜色，版式）如印刷品出现以上问题或错误，由客户自行承担责任本公司概不负责，敬请谅解；</p>
      <p class="p">6、当您选择自助设计时，为了保证文件的正常印刷制作，本公司有权利对没有加出血的图片、文字离裁切线过近小于12号的四色黑文字的文件进行印前调整，调整前无法对客户一一告知，敬请悉知；</p>
      <p class="p">7、对于使用RGB、专色填色出版时自动转换转为CMYK造成色差或跳为黑色印刷产生错误本公司无法给予补印或退货，敬请谅解；</p>
      <p class="p">8、印刷色差CMYK值误差10%以内本公司无法给予补印或退货，敬请谅解。因采用拼版印刷会有印刷色差，同一印刷文件在不同次印刷时色彩都会有差异，这些属于拼版印刷正常现象，如果您对颜色有严格要求请联系客服选用专版印刷；</p>
      <p class="p">9、印刷颜色不能以显示屏的颜色或打印出来的颜色为标准，每个显示屏和打印机所产生的颜色均不相同，请您读取CMYK值根据色值参照印刷色谱来比对颜色，请勿以“我感觉”、“我以为”、“我的显示器颜色很正”作为产品检验参照标准，敬请谅解；</p>
      <p class="p">10、紫色，深蓝色，橙色，咖啡色属印刷中敏感的不稳定色，这四种颜色每次印出来都会有偏差，同一批产品也许会有几个颜色，如果执意使用这四种颜色，产生色差本公司不给予补印或退货，敬请谅解；</p>
      <p class="p">11、请将线条设置大于0.076mm，小于0.076mm晒不出版造成线条缺失本公司无法给予补印或退货，敬请谅解；</p>
      <p class="p">12、文件有物件置入容器切勿锁定，拼大版时必须移动文件出现锁定而丢失内容本公司不承担责任，敬请谅解；</p>
      <p class="p">13、本公司成品数量误差在5%的范围内属正常，200张以下本公司只可按单价减款处理，无法给予补印，敬请谅解；</p>
      <p class="p">14、当个别文件需要我方设计师简单修改内容时，请您仔细检查文件中所有的内容（包括但不限于logo、文字、二维码、图案、图片、颜色、版式），如印刷品出现以上错误，由客户自行承担责任，本公司概不负责，敬请谅解；</p>
      <p class="p">15、客户收到货物时须确认货物完好无损后再签收，如发现纸箱外包装有损、潮湿等情况，请拒签快递签收单，如签收后发现有损毁等非质量问题引起的损失，由客户自行承担责任，本公司概不负责，敬请谅解；</p>
      <p class="p">16、为保障您的权益，对本公司产品质量有异议请在到货3天内向我公司提出退货或补印事宜。本公司对货到3天内没反映质量异议超出3天后不作退货或补印处理，敬请谅解。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt10',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt10 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:30px;
    }
  }
}
</style>
